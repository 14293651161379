import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import HeroImage from '../components/heroImage'
import HeroImageMobile from '../components/heroImageMobile'
import LawyerOneImage from '../components/lawyerOneImage'
import LawyerTwoImage from '../components/lawyerTwoImage'
import SEO from '../components/seo'
import AwardLogo from '../components/awardLogo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div id="section-start">
      <div
        className="hero-image container-fluid no-gutters d-none d-lg-block"
        style={{ padding: 0 }}
      >
        <div className="row no-gutters">
          <div className="col-12" style={{ padding: 0 }}>
            <HeroImage />
            <div className="row call-to-action justify-content-start align-items-center">
              <div className="col-10 col-md-6 col-lg-4 col-xl-3 offset-lg-1">
                <div className="call-to-action__inner">
                  <h2>K&S Rechtsanwälte</h2>
                  <p className="margin-20-top" style={{ fontSize: '1.4rem' }}>
                    Rufen Sie uns an für eine Ersteinschätzung Ihrer
                    individuellen Situation
                  </p>
                  <a
                    role="button"
                    className="btn btn-outline-primary w-100"
                    href="tel:061444029610"
                  >
                    <span
                      className="fas fa-phone"
                      style={{ marginRight: '12px' }}
                    />
                    06144 4029610
                  </a>
                  <a
                    role="button"
                    className="btn btn-outline-primary w-100 margin-20-top"
                    href="mailto:info@ksrechtsanwaelte.de"
                  >
                    <span
                      className="fas fa-envelope"
                      style={{ marginRight: '12px' }}
                    />{' '}
                    E-Mail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-block d-lg-none">
        <div className="row">
          <div className="col-12">
            <HeroImageMobile />
            <h3 className="text-center margin-20-top">
              Rufen Sie uns an für eine Ersteinschätzung Ihrer individuellen
              Situation
            </h3>
            <a
              role="button"
              className="btn btn-outline-primary w-100 margin-20-top"
              href="tel:061444029610"
            >
              <span className="fas fa-phone" style={{ marginRight: '12px' }} />
              06144 4029610
            </a>
            <a
              role="button"
              className="btn btn-outline-primary w-100 margin-20-top"
              href="mailto:info@ksrechtsanwaelte.de"
            >
              <span
                className="fas fa-envelope"
                style={{ marginRight: '12px' }}
              />{' '}
              E-Mail
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="container margin-120-top">
      <div className="row">
        <div className="col-12 col-md-6">
          <h1>Ihr Recht in guten Händen</h1>
          <p className="margin-20-top">
            Die Rechtsanwaltskanzlei Kanz & Sagolla betreut im Herzen des
            Rhein-Main-Gebiets im Raum Mainspitze (Bischofsheim,
            Ginsheim-Gustavsburg und Rüsselsheim) Privatpersonen, Körperschaften
            des öffentlichen Rechts und Unternehmen qualifiziert und mit vollem
            Engagement, außergerichtlich, wie auch vor Gericht. Immer mit
            besonderem Blick auf die persönlichen und wirtschaftlichen
            Interessen der Mandanten und fokussiert auf eine gute und intensive
            Zusammenarbeit, werden gemeinsam Lösungen für rechtliche
            Problemstellungen erarbeitet und umgesetzt.
          </p>
        </div>
        <div className="col-12 col-md-6 margin-40-top-sm">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              <AwardLogo />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="section-rechtsgebiete" className="container margin-120-top">
      <h2>Rechtsgebiete</h2>
      <div className="card-deck margin-40-top">
        <div className="card">
          <img
            className="card-img-top gray-image"
            src="/img/coins-1726618_640.jpg"
            alt="Card image cap"
          />
          <div className="card-body">
            <h5 className="card-title">Bank- und Kapitalmarktrecht</h5>
            <p className="">
              Als erfahrene Fachanwälte für Bank- und Kapitalmarktrecht haben
              die Rechtsanwälte Beatrice Sagolla und Simon Kanz eine besondere
              Expertise und unterstützen Sie gerne bei allen Rechtsfragen rund
              um:
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Kapitalanlagen und Beraterhaftung{' '}
              </li>
              <li className="list-group-item">Darlehen und Nachrangdarlehen</li>
              <li className="list-group-item">Bürgschaften</li>
              <li className="list-group-item">Leasing</li>
              <li className="list-group-item">
                Sicherheiten wie Pfandrechte, Hypotheken, Grundschulden und
                Abtretungen
              </li>
              <li className="list-group-item">Factoring</li>
              <li className="list-group-item">
                Zahlungsverkehr, Online Banking und Girokonto
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <img
            className="card-img-top gray-image"
            src="/img/housebuilding-3370969_640.jpg"
            alt="Card image cap"
          />
          <div className="card-body">
            <h5 className="card-title">Bau- und Architektenrecht</h5>
            <p>
              Als jahrelang im Bereich des Bau- und Architektenrechts tätige
              Rechtsanwälte haben Beatrice Sagolla und Simon Kanz eine besondere
              Fachkompetenz und unterstützen Sie gerne bei allen Rechtsfragen
              rund um:
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Baumängel</li>
              <li className="list-group-item">Bauträgerverträge</li>
              <li className="list-group-item">Architektenverträge</li>
              <li className="list-group-item">Planungsmängel</li>
              <li className="list-group-item">Bauzeitverzögerungen </li>
              <li className="list-group-item">Bauabnahmen</li>
              <li className="list-group-item">Werkverträge</li>
              <li className="list-group-item">
                Selbstständige Beweisverfahren
              </li>
              <li className="list-group-item">Kostenüberschreitungen</li>
            </ul>
          </div>
        </div>
        <div className="card">
          <img
            className="card-img-top gray-image"
            src="/img/binding-contract-948442_640.jpg"
            alt="Card image cap"
          />
          <div className="card-body">
            <h5 className="card-title">Weitere Rechtsgebiete</h5>
            <p>
              Gerne unterstützen Sie die Rechtsanwälte Beatrice Sagolla und
              Simon Kanz auch in folgenden Rechtsgebieten:
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Allgemeines Zivilrecht</li>
              <li className="list-group-item">Arbeitsrecht</li>
              <li className="list-group-item">
                Medizinrecht (inklusive Sozialrecht)
              </li>
              <li className="list-group-item">Mietrecht</li>
              <li className="list-group-item">Strafrecht </li>
              <li className="list-group-item">Verkehrsrecht</li>
            </ul>
          </div>
        </div>
      </div>
      <h3 className="margin-80-top">Rechtsgebiet nicht gefunden?</h3>
      <p>
        Fragen Sie uns trotzdem an! Wir beraten Sie
        gerne, ob eine Mandatsübernahme durch uns möglich ist oder wir empfehlen
        Ihnen gerne nach Möglichkeit einen spezialisierten Kollegen aus unserem
        umfangreichen Netzwerk.
      </p>
    </div>

    <div id="rechtsanwaelte" className="container margin-80-top">
      <div className="row">
        <div className="col-12">
          <h2>Rechtsanwälte</h2>
          <p className="margin-20-top">
            Die Rechtsanwälte Simon Kanz und Beatrice Sagolla zeichnen sich
            durch ihre persönliche Betreuung und enge Zusammenarbeit mit ihren
            Mandanten aus. Langjährige Erfahrung an Amts- Land- und
            Oberlandesgerichten, sowie regelmäßig aktualisierte
            Spezialkenntnisse in ihren Fachgebieten sind die Grundpfeiler
            erfolgreicher Zusammenarbeit und effektiver Problemlösung.
          </p>
        </div>
      </div>
      <div className="row margin-80-top">
        <div className="col-12 col-md-6 order-2 order-md-1">
          <h2>Beatrice Sagolla</h2>
          <p className="margin-20-top">
            Rechtsanwältin Beatrice Sagolla wurde 1985 in Weilburg an der Lahn
            geboren und absolvierte ihr Studium der Rechtswissenschaften an den
            Universitäten Gießen und Frankfurt. Ihr Referendariat mit
            Schwerpunkt in der praktischen Prozessführung, verbrachte sie am
            Landgericht Frankfurt am Main. Nach zwei erfolgreichen Staatsexamina
            wurde sie 2016 als Rechtsanwältin zugelassen. In der anwaltlichen
            Praxis deckt sie die Schwerpunkte Bau- und Architektenrecht,
            Arbeitsrecht, sowie Bank- und Kapitalmarktrecht ab. Darüber hinaus
            zeichnet sie sich durch ihre überdurchschnittlichen Erfahrungen in
            der außergerichtlichen Streitbeilegung aus. Im Dezember 2019 wurde
            ihr der Titel Fachanwältin für Bank- und Kapitalmarktrecht
            verliehen.
          </p>
        </div>
        <div className="col-12 col-md-6 order-1 order-md-2">
          <LawyerOneImage />
        </div>
      </div>
      <div className="row margin-120-top">
        <div className="col-12 col-md-6">
          <LawyerTwoImage />
        </div>
        <div className="col-12 col-md-6">
          <h2>Simon Kanz</h2>
          <p className="margin-20-top">
            Rechtsanwalt und Fachanwalt Simon Kanz wurde 1987 in Mainz geboren
            und absolvierte sein Studium der Rechtswissenschaften an der
            Johannes Gutenberg Universität zu Mainz. Sein Schwerpunktstudium
            umfasste das Steuer-, Gesellschafts- und Kapitalmarktrecht. Nach dem
            Referendariat am Landgericht Mainz und zwei erfolgreichen
            Staatsexamina ist er als Rechtsanwalt in den Tätigkeitsbereichen
            Bank- und Kapitalmarktrecht, Bau- und Architektenrecht, Medizinrecht
            und allgemeines Zivilrecht aktiv. Er hat 2017 erfolgreich den
            theoretischen Teil des Fachanwaltslehrgangs für Medizinrecht
            bestanden. Im September 2018 wurde ihm der Titel Fachanwalt für
            Bank- und Kapitalmarktrecht verliehen. Zudem engagiert sich
            Rechtsanwalt Kanz als Gemeindevertreter in der Gemeindevertretung
            Bischofsheim.
          </p>
        </div>
      </div>
    </div>

    <div id="section-standort" className="container margin-120-top">
      <div className="row">
        <div className="col-12">
          <h2>Hier finden Sie uns!</h2>
          <h5>
            Im Herzen des Rhein-Main-Gebiets im Raum Mainspitze (Bischofsheim,
            Ginsheim-Gustavsburg und Rüsselsheim) in unmittelbarer Nähe des
            Jobcenters
          </h5>
        </div>
      </div>
    </div>
    <div className="container margin-40-top">
      <div className="row">
        <div className="col-12">
          <div style={{ width: '100%' }}>
            <iframe
              title="Standort der Kanzlei"
              width="100%"
              height="600"
              src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Dresdener%20Str.%2011%2C%2065474%20Bischofsheim+(K%26S%20Rechtsanw%C3%A4lte)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="section-kontakt" className="container margin-120-top">
      <div className="row">
        <div className="col-12 col-md-6">
          <h2>Kontakt</h2>
          <p>
            Wenn Sie mit uns in schriftlichen Kontakt treten möchten, schreiben
            Sie uns einfach eine E-Mail:
          </p>
          <a
            role="button"
            className="btn btn-outline-primary d-block d-md-none"
            href="mailto:info@ksrechtsanwaelte.de"
          >
            <span className="fas fa-envelope" style={{ marginRight: '12px' }} />{' '}
            info@ksrechtsanwaelte.de
          </a>
          <a
            role="button"
            className="btn btn-outline-primary d-none d-md-inline"
            href="mailto:info@ksrechtsanwaelte.de"
          >
            <span className="fas fa-envelope" style={{ marginRight: '12px' }} />{' '}
            info@ksrechtsanwaelte.de
          </a>
        </div>
        <div className="col-md-12 d-block d-md-none margin-40-top" />
        <div className="col-12 col-md-6">
          <h2>Öffnungszeiten</h2>
          <p>Unsere telefonischen Sprechzeiten sind:</p>
          <p>Mo bis Do: 9:00 - 13:00 Uhr / 14:00 - 18:00 Uhr</p>
          <p>Fr: 9:00 - 13:00 Uhr</p>
          <p>Termine außerhalb der Öffnungszeiten nach Vereinbarung möglich.</p>
          <a
            role="button"
            className="btn btn-outline-primary d-block d-md-none"
            href="tel:061444029610"
          >
            <span className="fas fa-phone" style={{ marginRight: '12px' }} />
            06144 4029610
          </a>
          <a
            role="button"
            className="btn btn-outline-primary d-none d-md-inline"
            href="tel:061444029610"
          >
            <span className="fas fa-phone" style={{ marginRight: '12px' }} />
            06144 4029610
          </a>
        </div>
      </div>
    </div>

    <div id="section-cost" className="container margin-120-top">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-6">
          <h2>Kosten</h2>
          <p>
            Die Höhe von Anwaltsgebühren wird häufig überschätzt. Es lohnt sich
            zumeist, für einen Rechtsanwalt Geld auszugeben, da man durch
            anwaltlichen Rat z.B. einen aussichtslosen Prozess vermeiden kann.
            Obsiegt man in einem Rechtsstreit, so wird die gegnerische Partei in
            der Regel zur gesamten Kostenerstattung, inklusive der
            Rechtsanwaltskosten, verpflichtet. Bei bestehender
            Rechtsschutzversicherung übernimmt diese zumeist sämtliche Kosten
            des Rechtsstreits, unabhängig vom Ausgang des Verfahrens.
            <br />
            Auch vor Abschluss wichtiger Verträge, sollte der Rat eines
            Rechtsanwalts eingeholt werden, da dieser Kosten und Ärger ersparen
            kann.
          </p>
          <p>
            Gerne besprechen wir mit Ihnen die konkret zu erwartenden Kosten und
            die Möglichkeit individueller Gebührenvereinbarungen.
          </p>
          <p>
            <a
              href="https://www.brak.de/fuer-verbraucher/kosten/anwaltsverguetung/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Weitere Informationen
            </a>
          </p>
        </div>
      </div>
    </div>
    <div className="container margin-80-top">
      <div className="row">
        <div className="col-12" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
